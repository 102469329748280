const DEFAULT_KEYS = ['is-size-', 'mb-', 'mt-', 'pt-', 'pb-', 'has-background-']

export default {
  props: {
    options: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    styles: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      default_styles: {}
    }
  },
  computed: {
    computed_styles() {
      if (!this.styles) return {}
      const keys = Object.keys(this.default_styles)
      const isKeyMatching = key => {
        const hasKey = DEFAULT_KEYS.find(default_key =>
          key.includes(default_key)
        )

        return hasKey
          ? keys.find(default_key => default_key.includes(hasKey))
          : false
      }

      return Object.entries(this.styles).reduce(
        (prev, [key, value]) => {
          let key_matching
          key_matching = isKeyMatching(key)

          if (key_matching) delete prev[key_matching]

          prev[key] = value

          return prev
        },
        { ...this.default_styles }
      )
    }
  }
}
