
import StyledBlock from '@/components/_mixins/StyledBlock'

export default {
  name: 'TitleBlock',
  mixins: [StyledBlock],
  props: {
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      default_styles: {
        'is-size-4-5': true,
        'pt-2': true,
        // 'pb-3': true,
        'mb-4': true,
        'has-text-weight-bold': true
      }
    }
  }
}
